import $ from 'jquery'
const $doc = $(document)

function backToTop(event) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export default function initBackToTop(){
  $doc.on('click', '.js-back-to-top', backToTop )
}
