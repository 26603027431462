import $ from 'jquery'

export default function initMicrositeScripts(){
    // Profile toggle
    $(".cos-profile-toggle").on("click", function(){
        $(this).parents(".cos-profile").toggleClass("expanded");
        $(this).parents(".cos-profile").find(".cos-profile-detail").toggle();
        $("body").toggleClass("masked");
        return false;
    });

    // Masking
    $("#mask").on("click", function(){
        $("body").removeClass("masked");
        $(".cos-profile").removeClass("expanded");
        $(".cos-profile-detail").hide();
        return false;
    });

    // Accordion toggle
    $(".cos-accordion > li, .cos-accordion-trigger, .cos-accordion h4 a").on("click touch", function(){
        if ($(this).is("li")) {
            if (!$(this).hasClass("expanded")) {
                $(this).addClass("expanded");
                $(this).find(".cos-plan").slideDown();
            }
        } else {
            if ($(this).parents("li").hasClass("expanded")) {
                $(this).parents("li").removeClass("expanded");
                $(this).parents("li").find(".cos-plan").slideUp();
            } else {
                $(this).parents("li").addClass("expanded");
                $(this).parents("li").find(".cos-plan").slideDown();
            }
        }
        
        return false;
    });

    //Annual report 2019

    if($('.chart').length) {

        (function($) {
            $(function(){
                // Draw charts
                var expenses = [
                    { name: "Programs", y: 88 },
                    { name: "Fundraising", y: 9 },
                    { name: "Admin", y: 2 },
                    { name: "In-kind expense", y: 1 }
                ];
                
                var revenue = [
                    { name: "Foundation", y: 51 },
                    { name: "Corporate", y: 29 },
                    { name: "Individuals", y: 14 },
                    { name: "Government", y: 4 },
                    { name: "In-kind income", y: 2 }
                ];
                
                function drawChart(container, data) {
                    var chart = new Highcharts.Chart({
                        chart: {
                            backgroundColor: "transparent",
                            height: "50%",
                            renderTo: container,
                            type: "pie"
                        },
                        colors: ['#e4efdc','#c8dfb9','#accf98','#90bf77','#73af55'],
                        credits: { enabled: false },
                        legend: { enabled: false },
                        plotOptions: {
                            pie: {
                                dataLabels: {
                                    color: "#73af55",
                                    distance: 10,
                                    enabled: true,
                                    formatter: function() {
                                        return this.point.name + ": <strong>" + Highcharts.numberFormat(this.percentage, 0) + "%</strong>";
                                    },
                                    style: {
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                        textOutline: 0
                                    }
                                },
                                shadow: false,
                                states: {
                                    hover: { enabled: false }
                                }
                            }
                        },
                        series: [{
                            data: data
                        }],
                        title: { text: null },
                        tooltip: { enabled: false }
                    });
                }
                
                drawChart("expenses", expenses);
                drawChart("revenue", revenue);
            });
        })(jQuery);
    }
    
}